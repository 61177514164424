import React from "react"
import Container from '@mui/material/Container';
import { Typography } from "@mui/material";

export default function NoPage() {
  return (
    <Container sx={{ pt: 8 }} maxWidth="sm">
        <Typography variant="h5" align="center" color="text.secondary" paragraph>
        404 Page Not Found
        </Typography>
    </Container>
  )
}
