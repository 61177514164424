// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAL_43prZScwyflqsFDL971gc2tgWrwOmQ",
  authDomain: "minor-distractions.firebaseapp.com",
  projectId: "minor-distractions",
  storageBucket: "minor-distractions.appspot.com",
  messagingSenderId: "633961840417",
  appId: "1:633961840417:web:072c9222b3fe3e75d3c860"
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);

export default getFirestore();
