import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export default function GameCards({game}) {

    let navigate = useNavigate();

    return (
        <Grid item key={game.id} xs={12} sm={12} md={6}>
          <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
              <CardActionArea onClick={()=>{
                navigate(game.link);
              }}>
                  <CardMedia
                      component="img"
                      sx={{
                      pt: '0%',
                      }}
                      image={game.image}
                      alt="Game Image"
                  />
                  <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                      {game.title}
                      </Typography>
                      <Typography>
                      {game.content}
                      </Typography>
                  </CardContent>
            </CardActionArea>
            <CardActions>
              <Button sx={{ flexGrow: 1 }} size="small">Play</Button>
            </CardActions>
          </Card>
        </Grid>
    );
  }