import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import Link from '@mui/material/Link';

export default function TopHeader() {

  let navigate = useNavigate();

  return (
      <AppBar position="sticky">
        <Link href="\">
          <Toolbar>
            <img src="/images/Bunz_with_text_bw.svg" width="350" alt="Minor Distractions" />
          </Toolbar>
        </Link>
      </AppBar>
  );
}

