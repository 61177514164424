import React from 'react'
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';

import { firestore, doc, onSnapshot, collection, query, where, setDoc, addDoc, orderBy, limit} from "firebase/firestore";
import {useState,useEffect} from 'react';

import db from '../../FireBase'
import {handleNew, handleEdit, handleDelete, handleQueryDelete} from '../../Utils'
import { ConstructionOutlined } from '@mui/icons-material';

export default function HighscoresOverall() {

    const [players, setPlayers] = useState([]);

/*     useEffect(() => 
        onSnapshot(collection(db, 'users'), (snapshot) =>
            setPlayers(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
        ),[]); */

    useEffect(() => {
        const collectionRef = collection(db, 'players');
        const q = query(collectionRef, where("scoreSa1", ">=", 0), orderBy('scoreSa1', 'desc'), orderBy('scoreSa2'), limit(10));

        const unsub = onSnapshot(q, (snapshot) =>
            setPlayers(snapshot.docs.map(doc => ({...doc.data(), id: doc.id, open: false})))
        );
    return unsub;
    },[]);

    const formatTime = (time) => {
        var sec_num = parseInt(time, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}

        if(hours < 1 ) {
            return minutes + ':' + seconds;    
        }
        return hours + ':' + minutes + ':' + seconds;
    }

    const [open, setOpen] = React.useState(true);

    const handleClick = (player) => {
      setOpen(!open);
      player.open = open;
    };    

    const unixToDate = (date) => {
        if (date == null){
            return 'not a member'
        }
        let d = new Date(date.seconds * 1000);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${da}-${mo}-${ye}`
    }

    return (
//<ListItemText primary={player.userName} secondary={player.sandTime} onClick={() => handleDelete(player)}/>
        <Container maxWidth='xs'>
            <Typography variant='h3' align='center' pb='34px'>
                Highscores
            </Typography>
            {players.map((player) => (
                <List sx={{pt: '2px', pb: '2px'}} key={player.discord.id}>
                    <ListItemButton onClick={() => handleClick(player)} alignItems="flex-start" key={player.discord.id} component="a" sx={{bgcolor: '#fafcff'}}>
                        <ListItemAvatar>
                            <Typography>
                                {players.indexOf(player) + 1}
                            </Typography>
                        </ListItemAvatar>                    
                        <ListItemAvatar>
                            <Avatar alt='Discord Avatar' src={player.discord.avatar_url} />
                        </ListItemAvatar>
                        <ListItemText primary={player.discord.display_name + '#' + player.discord.discriminator} secondary={formatTime(player.scoreSa2/10)} />
                        {player.scoreSa1}
                    </ListItemButton> 
                    <Divider />
                    <Collapse in={player.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding key={player.discord.id}>
                            <Typography>
                                {unixToDate(player.discord.joined_at)}
                            </Typography>
                        </List>
                    </Collapse>
                </List>
            ))}
        </Container>
    );
  }