import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { grey, amber, orange, green, lightBlue } from '@mui/material/colors';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';
import TopHeader from './TopHeader'
import Footer from './Footer'

import Home from './pages/Home'
import About from './pages/About'
import NoPage from './pages/NoPage'
import CastlesVsTanks from './pages/games/CastlesVsTanks'
import FlingoIsland from './pages/games/FlingoIsland'
import SailsOfSand from './pages/games/SailsOfSand'
import DisasterAverted from './pages/games/DisasterAverted'
import HighscoresOverall from './pages/highscores/HighscoresOverall'

const theme = createTheme({
  palette: {
    primary: {
      main: orange[600],
    }
  },
});

export default function App() {
  return (
    <Router>  
      <ThemeProvider theme={theme}>
      <CssBaseline />
        <TopHeader />
          <Box
            sx={{
              bgcolor: grey[50],
              pt: 8,
              pb: 6,
            }}
          >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/castlesvstanks" element={<CastlesVsTanks />} />
            <Route path="/flingoisland" element={<FlingoIsland />} />
            <Route path="/sailsofsand" element={<SailsOfSand />} />
            <Route path="/disasteraverted" element={<DisasterAverted />} />
            <Route path="/h/overall" element={<HighscoresOverall />} />
            <Route path="/*" element={<NoPage />} />
          </Routes>
          </Box>
        <Footer />
      </ThemeProvider>
    </Router>
  );
}